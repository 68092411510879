@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.tim-form {
    margin-top: 48px;
    padding: 48px;
    background: #2F297E;
    border-radius: 8px;
}

.tim-input {
    height: 48px !important;
    background: rgba(255, 255, 255, 0.38);
    border-color: rgba(255, 255, 255, 0.38);
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    /* font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em; */
    /* color: #FFFFFF; */
}

.tim-label {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.0025em;
    color: #FFFFFF;

}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    color: rgba(0, 0, 0, 0.6);
    opacity: 10;
}

.tim-FormBtn {
    height: 48px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #6854FF;
}

.tim-FormBtn:hover,
.tim-FormBtn:focus {
    color: white;
    background-color: #6854FF;
}

@media only screen and (max-width: 425px) {
    .tim-form {
        margin-top: 20px;
        padding: 15px;
        background: #2F297E;
        border-radius: 8px;
    }
    .form-col{
        padding: 5px 10px;
    }
}
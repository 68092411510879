.tim-bgLight {
    background: #FAFAFA;
    padding: 96px 24px 48px 24px;
}

.tim-subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 64px;
}

.tim-card {
    /* height: auto; */
    height: 232px;
    border-radius: 8px;
    background: unset;
    border: 1px solid #FAFAFA;
}

.tim-card:hover {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    transition: 0.3s;
}

.tim-card:hover .tim-cardIconDiv {
    width: 64px;
    height: 64px;
    background: unset;
}

.tim-card:hover .tim-cardIcon {
    font-size: 48px;
}

.tim-card .card-body {
    padding: 24px;
}

.tim-cardIconDiv {
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-bottom: 32px;
}

.tim-cardIconDiv.purple {
    background: #F4E8FF;
}

.tim-cardIconDiv.yellow {
    background: #FFF1DC;
}

.tim-cardIconDiv.green {
    background: #D9FFE9;
}

.tim-cardIconDiv.red {
    background: #FFECF5;
}

.tim-cardIcon {
    font-size: 32px;
}

.tim-cardIcon.red {
    color: #F82E8C;
}

.tim-cardIcon.yellow {
    color: #DB8100;
}

.tim-cardIcon.green {
    color: #219653;
}

.tim-cardIcon.purple {
    color: #9B51E0;
}

.tim-card .card-body .card-title.h5 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
}

.tim-card .card-body .card-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 425px) {
    .tim-subtitle {
        font-size: 30px;
        line-height: 10px;
        margin-bottom: 24px;
    }
    .tim-bgLight {
        background: #FAFAFA;
        padding: 50px 24px 24px 24px;
    }
}
.tim-BgColorFooter {
    padding: 48px 0;
}

.tim-follow {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 24px;
    padding-top: 6px;
}

.tim-footerAlt {
    padding: 24px 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
}

.tim-com {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
}

.bet-iconText{
    gap: 11px;
}

@media only screen and (max-width: 425px) {
    .tim-footerAlt {
        padding: 10px 0;
    }
    .tim-BgColorFooter {
        padding: 30px 24px;
    }
}
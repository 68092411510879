.tim-accordion {
    max-width: 792px;
    margin: auto;
    margin-top: 64px;
}

.accordion-collapse.collapse.show,
.accordion-collapse.collapse {
    background: #FBF7FF !important;
    text-align: left !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 8px !important;
}

.accordion-button:not(.collapsed) {
    background: #FBF7FF;
    box-shadow: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 8px !important;
}

.accordion-button:focus {
    z-index: 3;
    border-color: unset;
    outline: 0;
    box-shadow: unset;
}

.accordion-item {
    background-color: #fff;
    border-radius: 8px; 
    margin-bottom: 10px;
}

.collapsing {
    /* transition: height; */
    text-align: left !important;
    background: #FBF7FF !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 8px !important;
}

.accordion-item .accordion-button.collapsed { 
    background: #FAFAFA;
    border: 1px solid #FAFAFA;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.accordion-item:first-of-type, .accordion-item:last-of-type, .accordion-item  {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    /* border: 1px solid #9B51E0 !important; */
    
}

.accordion-item {
    border: none;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: unset;
}

.accordion-item:last-of-type .accordion-button {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.accordion-item.accordion-button {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.accordion-item:first-of-type ,.accordion-item,.accordion-item:last-of-type {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}


.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius:8px !important;
    border-bottom-left-radius: 8px !important;
}

.accordion-item:first-of-type .accordion-button.collapsed {
    border-top-right-radius:8px !important;
    border-top-left-radius: 8px !important;
}

.accordion-item:first-of-type .accordion-button {
    border-top-right-radius:8px !important;
    border-top-left-radius: 8px !important;
}

.accordion-item .accordion-button.collapsed {
    border-top-right-radius:8px !important;
    border-top-left-radius: 8px !important;
    border-bottom-right-radius:8px !important;
    border-bottom-left-radius: 8px !important;
}

.accordion-button::after {
    background-image: url(/src/components/style/icons/iconrow.png);
  
}

.accordion-button:not(.collapsed)::after {
    background-image: url(/src/components/style/icons/iconrow.png);
}
.tim-BgBlue {
    background: #2F297E;
    padding: 58px 0 38px 0;
}

.tim-headerOuther {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;
}

.tim-outherPage {
    padding: 48px 0;
}

.tim-container {
    /* max-width: 583px; */
}

.tim-subHeaderPage {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 24px;
}

.tim-contHeaderPage {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
}

.tim-subHeadLi {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
}

.tim-subUl {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
}

.tim-subUl li {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
}

.carousel-control-prev-icon {
    background-image: url('../icons/left.svg');
}

.carousel-control-next-icon {
    background-image: url('../icons/right.svg');
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 20rem;
    height: 20rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.tim-EtrafliImg{
    height: 100%;
    width: 100%;
    
}


@media only screen and (max-width: 425px) {
    .tim-outherPage {
        padding: 10px 0;
    }
    .tim-headerOuther {
        font-family: 'Inter',sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #FFFFFF;
    }
    .tim-subHeaderPage {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        letter-spacing: 0.25px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 24px;
        margin-top: 24px;
    }
}
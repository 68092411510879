.carusel {
    background-color: #2F297E;
    padding: 48px 24px;
}

.tim-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 120px;
    text-align: center;
    letter-spacing: -1.5px;
    color: #FFFFFF;
    word-wrap: normal;
    margin-bottom: 0;
}

.tim-headercontext {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0;
    margin-top: 24px;
    display: inline-block;
    max-width: 598px;
    word-wrap: break-word;
}

.tim-DemoBtn {
    width: 230px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0;
    margin-top: 48px;
}

.tim-DemoBtn a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: rgba(0, 0, 0, 0.87);
}

.tim-DemoBtn:hover,
.tim-DemoBtn:active,
.tim-DemoBtn:focus {
    background: #EEEDFF;
    color: #6854FF !important;
}

.swiper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
}

.swiper.red {
    border: 1px solid #FF90C0;
    border-radius: 8px;
}

.swiper.yellow {
    border: 1px solid #E3B650;
    border-radius: 8px;
}

.swiper.blue {
    border: 1px solid #56CCF2;
    border-radius: 8px;
}

.swiper.purple {
    border: 1px solid #C998F4;
    border-radius: 8px;
}

.swiper.green {
    border: 1px solid #7BCF98;
    border-radius: 8px;
}

/* new */

.swiper.greenlight {
    border: 1px solid #B1C058;
    border-radius: 8px;
}

.swiper.orange {
    border: 1px solid #DA8773;
    border-radius: 8px;
}

.swiper.greenHello {
    border: 1px solid #63FFF4;
    border-radius: 8px;
}

.swiper.orangeLight {
    border: 1px solid #FF8282;
    border-radius: 8px;
}

.swiper.purpleLight {
    border: 1px solid #A3B1FF;
    border-radius: 8px;
}

.swiper.pinkHello {
    border: 1px solid #FF62F9;
    border-radius: 8px;
}

.rcs_buttonWrapper {
    /* border: 1px solid #7BCF98;
    border-radius: 8px; */
    border: 1px solid white;
    border-radius: 50%;
}

/* .rcs_slideCon.itemWrapper {
    width: 175px;
    height: 160px;
} */

.rcs_slideCon .itemWrapper img {
    width: 100%;
    height: 100%;
}

.rcs_slideCon {
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 0 32px;
}

.rcs_slideCon .itemWrapper {
    width: 175px !important;
    height: 160px !important;
    margin: 0px 0px !important;
    padding: 0 !important;
}

.rcs_slideCon .itemWrapper:hover {
    width: 191px !important;
    height: 176px !important;
    margin: 0px 0px !important;
    background: rgba(255, 255, 255, 0.38) !important;
    border-radius: 8px;
    transition: 0.3s;
}

.rcs_dotsSet div {
    background: #707070;
}

.slider-right {
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 84px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.slider-left {
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 84px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.iconDiv {
    font-size: 32px;
}

.rcs_buttonWrapper {
    top: 45%;
}

.sliderText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #FFFFFF;

}

.icon.green {
    color: #7BCF98;
}

.icon.yellow {
    color: #E3B650;
}

.icon.blue {
    color: #56CCF2;
}

.icon.red {
    color: #FF90C0;
}

.icon.purple {
    color: #C998F4;
}

/* new */
.icon.greenlight {
    color: #99AA38;
}

.icon.orange {
    color: #DA8773;
}

.icon.greenHello {
    color: #00BFB2;
}

.icon.orangeLight {
    color: #FF8282;
}

.icon.purpleLight {
    color: #A9B7FF;
}

.icon.pinkHello {
    color: #FF62F9;
}

@media only screen and (max-width: 1399px) {
    .tim-title {
        font-size: 47px;
        line-height: 67px;
    }
}

@media only screen and (max-width: 900px) {
    .tim-title {
        font-size: 47px;
        line-height: 67px;
    }
}

@media only screen and (max-width: 425px) {
    .tim-title {
        font-size: 30px;
        line-height: 39px;
    }

    .carousel-item {
        height: 282px;
    }
}
.tim-modulsHeader {
    background: #FFFFFF;
    padding: 96px 24px 48px 24px;
}

.tim-subtitleUnder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 24px;
}

.tim-underDetails {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    display: inline-block;
    max-width: 650px;
    word-wrap: break-word;
}

.tim-subUnderTitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
    max-width: 411px;
    word-wrap: break-word;
    margin-bottom: 24px;
}

.tim-subUnderDetails {
    max-width: 381px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
    word-wrap: break-word;
    margin-bottom: 24px;
}

/* modul body */

.tim-ModulBgWhiteColor {
    background: #FFFFFF;
    padding: 96px 24px 48px 24px;
}

.tim-ModulBgLightColor {
    background: #FAFAFA;
    padding: 96px 24px 48px 24px;
}

.tim-modulBtn ,.tim-modulBtn:hover {
    width: 56px;
    height: 56px;
    border-radius: 84px;
    padding: 0;
}

.tim-modulBtn.blue{
    background: #02B2F1;
    border: 1px solid #02B2F1;
    color: #FFFFFF !important;
}

.tim-modulBtn.purple{
    background: #9B51E0;
    border: 1px solid #9B51E0;
    color: #FFFFFF !important;
}

.tim-modulBtn.brown{
    background: #D57200;
    border: 1px solid #D57200;
    color: #FFFFFF !important;
}

.tim-modulBtn.pink{
    background: #F82E8C;
    border: 1px solid #F82E8C;
    color: #FFFFFF !important;
}

.tim-modulBtn.green{
    background: #219653;
    border: 1px solid #219653;
    color: #FFFFFF !important;
}
/* new */
.tim-modulBtn.greenlight{
    background: #839329;
    border: 1px solid #839329;
    color: #FFFFFF !important;
}

.tim-modulBtn.orange{
    background: #9E5948;
    border: 1px solid #9E5948;
    color: #FFFFFF !important;
}

.tim-modulBtn.greenHello{
    background: #0EA297;
    border: 1px solid #0EA297;
    color: #FFFFFF !important;
}

.tim-modulBtn.orangeLight{
    background: #FF4646;
    border: 1px solid #FF4646;
    color: #FFFFFF !important;
}

.tim-modulBtn.purpleLight{
    background: #2D45C2;
    border: 1px solid #2D45C2;
    color: #FFFFFF !important;
}

.tim-modulBtn.pinkHello{
    background: #AA19A4;
    border: 1px solid #AA19A4;
    color: #FFFFFF !important;
}


.tim-modulBtn a{
    display: inline-block;
    width: 100%;
    height: 100%;   
    padding: 16px;
    color: white !important;
}

@media only screen and (max-width: 425px) {
    .tim-subtitleUnder {
        font-size: 27px;
        line-height: 35px;
        text-align: center;
        margin-bottom: 20px;
    }
    .tim-modulsHeader {
        background: #FFFFFF;
        padding: 50px 24px 48px 24px;
    }
    .tim-ModulBgLightColor {
        background: #FAFAFA;
        padding: 40px 24px 24px 24px;
    }
    .tim-ModulBgWhiteColor {
        background: #FFFFFF;
        padding: 40px 24px 24px 24px;
    }
}
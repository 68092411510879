/* Navbar */

.tim-navbar {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px) !important;
    height: 96px;
    padding: 0;
}

.tim-nav {
    gap: 56px;
}

.tim-logo {
    height: 48px;
}

/* Link */

.tim-link {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87) !important;
}

.tim-link.active {
    font-weight: 700;
    letter-spacing: 0.0015em;
}

.tim-apply {
    background-color: #6854FF;
    border: 1px solid #6854FF;
    border-radius: 8px;
    width: 138px;
    height: 48px;
    padding: 0;
}

.tim-apply:hover {
    background-color: #3C2CF1;
    border: 1px solid #3C2CF1;
}

.tim-apply a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #FFFFFF !important;
}

/* @media */

@media only screen and (max-width: 1399px) {
    .tim-nav {
        gap: 0px;
    }
}

@media only screen and (max-width: 425px) {
    .timpage {
        display: none;
    }
}